<template>
  <div class="field">
    <label for="attribute-value">
      {{ attribute.label }}
    </label>
    <div>
      <ExtraForm
        :id="`attribute-value-for-${attribute.name}`"
        ref="extraForm"
        name="attribute-value"
        :field="{ ...attribute, value }"
        :use-value-only="true"
        @update:value="updateValue($event.toString(), attribute.id)"
      />
    </div>
  </div>
</template>

<script>
import ExtraForm from '@/components/ExtraForm';

export default {

  name: 'ProjectAttributeForm',

  components: {
    ExtraForm,
  },

  props: {
    attribute: {
      type: Object,
      default: () => {
        return {};
      }
    },
    formProjectAttributes: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  computed: {
  /**
   * Retrieves the current value of a specific project attribute.
   * This computed property checks the array of project attributes to find the one that matches
   * the current attribute's ID. If the attribute is found, its value is returned.
   * Otherwise, null is returned to indicate that the attribute is not set for the current project.
   *
   * @returns {String|null} The value of the attribute if it exists in the project's attributes; otherwise, null.
   */
    value() {
      // Searches for the attribute within the array of attributes associated with the project.
      const projectAttribute = this.formProjectAttributes.find(el => el.attribute_id === this.attribute.id);
      // Returns the value of the attribute if it exists, or null if the attribute is not found.
      return projectAttribute ? projectAttribute.value : null;
    },
  },

  created() {
    // Checks if the component is being used in the context of creating a new project and attribute's default value is set
    if (this.$route.name === 'project_create' && this.attribute.default_value !== null) {
      // If so, initializes the attribute's value with its default value as defined in the attribute's settings.
      this.updateValue(this.attribute.default_value, this.attribute.id);
    }
  },

  methods: {
    /**
     * Updates or adds a value for a specific attribute in the project.
     * This method emits an event to update the project's attributes with a new value for a given attribute ID.
     * It is typically called when the user changes the value of an attribute in the UI.
     *
     * @param {String} value - The new value for the attribute.
     * @param {Number} attributeId - The unique ID of the attribute being updated or added to the project.
     */
    updateValue(value, attributeId) {
      // Emits an event to the parent component, requesting an update to the project's attributes.
      this.$emit('update:project_attributes', { value, attributeId });
    }
  }
};
</script>
